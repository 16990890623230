import React from 'react'
import Layout from '../../components/layout/layout'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'


const DevelopmentTeam = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - Development Team" />
    <CCBreadcrumb
      pathname={props.location.pathname}
      title={'Development Team'}
      replace={props.replace}
    />
    <Container fluid className="mb-5">
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="cardHeader">
                      <h1 className="headerFontBlue">Development Team</h1>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">Advisory Board</p>
                      <ul className="list-unstyled">
                        <li>Mark Chandler, PhD</li>
                        <li>NASA/Goddard Institute for Space Studies</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Steven Clemens, PhD</li>
                        <li>Geological Sciences, Brown University</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Ginger Davis</li>
                        <li>Brevard Public Schools, Florida</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Christopher Hedeen</li>
                        <li>Oregon City High School, Oregon</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Beverly Law, PhD</li>
                        <li>College of Forestry, Oregon State University</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Cynthia Long</li>
                        <li>Douglas County High School, Colorado</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Judy Scotchmoor</li>
                        <li>University of California Museum of Paleontology</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Colleen M. Works</li>
                        <li>Corvallis High School, Oregon</li>
                      </ul>

                      <p className="lead">Content Reviewers and Scientific Collaborators</p>
                      <ul className="list-unstyled">
                        <li>Mark Chandler, PhD</li>
                        <li>NASA/Goddard Institute for Space Studies</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Steven Clemens, PhD</li>
                        <li>Geological Sciences, Brown University</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Beverly Law, PhD</li>
                        <li>College of Forestry, Oregon State University</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>David Pinkerton, PhD</li>
                        <li>Science Education Private Consultant</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Judith Lean, PhD</li>
                        <li>United States Naval Research Laboratory</li>
                      </ul>

                      <p className="lead">Biological Sciences Curriculum Study</p>
                      <ul className="list-unstyled">
                        <li>Janet Carlson, PhD</li>
                        <li>Executive Director</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Pam Van Scotter</li>
                        <li>Associate Director</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Stephen R. Getty, PhD</li>
                        <li>Project Director, Principal Investigator</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Paul Beardsley, PhD</li>
                        <li>Science Educator</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Elaine Howes, PhD</li>
                        <li>Science Educator</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Betty Stennett</li>
                        <li>Science Educator</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Molly Stuhlsatz</li>
                        <li>Research Associate</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Jon Adams</li>
                        <li>Project Assistant</li>
                      </ul>

                      <p className="lead">Oregon Public Broadcasting</p>
                      <ul className="list-unstyled">
                        <li>David Davis</li>
                        <li>Vice President, Television Production</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Catherine Stimac</li>
                        <li>Executive Producer, Education Production</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Heather Young</li>
                        <li>Senior Web Designer/Developer</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Craig Giffen</li>
                        <li>Web Developer</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Renato Rodrigues</li>
                        <li>Research Associate</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Matthew Kin</li>
                        <li>Research Associate</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Ann McGarry</li>
                        <li>Associate Producer</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Michael Bendixen</li>
                        <li>Videographer/Editor</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Greg Bond</li>
                        <li>Video Editor</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Lisa Kallem</li>
                        <li>Video Editor</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Tom Shrider</li>
                        <li>Video Editor</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Randy Layton</li>
                        <li>Audio Technician</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Time-Lapse Video</li>
                        <li>Uncage the Soul Video Production</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Tammy Layton</li>
                        <li>Video Graphics</li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>Debra Lindland</li>
                        <li>Intern</li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
</Layout>
)

export default DevelopmentTeam
